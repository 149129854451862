function loadHelpwiseScript() {
  if (typeof window !== 'undefined') {
    window.helpwiseSettings = { widget_id: '65b4f4b36d8c6', align: 'right' };
    
    const script = document.createElement('script');
    script.src = 'https://cdn.helpwise.io/assets/js/livechat.js';
    script.async = true;
    document.body.appendChild(script);
  }
}

export { loadHelpwiseScript };
